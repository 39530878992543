import React from 'react';
import Icon from '../../styles/atoms/icons';

const avatars = [
  'avatar-f1',
  'avatar-f2',
  'avatar-f3',
  'avatar-f4',
  'avatar-f5',
  'avatar-m6',
  'avatar-m7',
  'avatar-m8',
  'avatar-m9',
  'avatar-m10',
];

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

const randomAvatar = () => {
  const getAvatar = avatars[getRandomInt(avatars.length - 1)];
  return <Icon id={getAvatar} isImage alt="avatar" />;
};

export default randomAvatar;
